import React, { Component } from 'react';
import Modal from '../../modal/modal.component';
import DateTimeInput from '../../date-time-input/dateTimeInput.component';
import CustomInput from '../../custom-input/customInput.component';
import DropdownSelect from '../../dropdown-select/dropdownSelect.component';
import styles from './styles/contentCustomRecurrence.module.scss';
import { withTranslation } from 'react-i18next';

const weekOfMonthOrdinalMap = {
    'FIRST': 1,
    'SECOND': 2,
    'THIRD': 3,
    'FOURTH': 4,
    'LAST': -1
};

function parseWeekOfMonthOrdinal(ordinalKey) {
    return weekOfMonthOrdinalMap[ordinalKey];
}


class ContentCustomRecurrence extends Component {
    constructor(props) {
        super(props);

        const { initialRecurrencePattern } = props;

        this.state = {
            frequency: initialRecurrencePattern.frequency || 'DAILY',
            repeatInterval: initialRecurrencePattern.repeatInterval || 1,
            selectedDaysOfWeek: initialRecurrencePattern.daysOfWeek || [],
            dayOfMonth: initialRecurrencePattern.dayOfMonth || '',
            weekOfMonthOrdinal: initialRecurrencePattern.weekOfMonthOrdinal || '',
            dayOfWeek: initialRecurrencePattern.dayOfWeek || '',
            patternEndDate: initialRecurrencePattern.patternEndDate || '',
            monthlyOption: initialRecurrencePattern.dayOfMonth ? 'dayOfMonth' : 'weekOfMonth',
            errors: {},
            validate: false,
        };
    }

    handleFrequencyChange = (selectedOptions) => {
        const frequency = selectedOptions[0]?.key || 'DAILY';
        this.setState({ frequency });
    }

    handleIntervalChange = (value) => {
        this.setState({ repeatInterval: value });
    }

    toggleDayOfWeek = (day) => {
        this.setState((prevState) => {
            const { selectedDaysOfWeek } = prevState;
            if (selectedDaysOfWeek.includes(day)) {
                return { selectedDaysOfWeek: selectedDaysOfWeek.filter(d => d !== day) };
            } else {
                return { selectedDaysOfWeek: [...selectedDaysOfWeek, day] };
            }
        });
    }

    handleMonthlyOptionChange = (option) => {
        this.setState({ monthlyOption: option });
    }

    handleDateChange = (date) => {
        this.setState({ patternEndDate: date });
    }

    handleInputChange = (name, value) => {
        this.setState({ [name]: value });
    }

    validateAndSubmit = () => {
        const { frequency, repeatInterval, selectedDaysOfWeek, dayOfMonth, weekOfMonthOrdinal, dayOfWeek, patternEndDate, monthlyOption } = this.state;
        const { t } = this.props;
        const errors = {};

        if (!repeatInterval || repeatInterval <= 0) {
            errors.repeatInterval = t("CONTENT.CUSTOM_RECURRENCE.IntervalPositive");
        }

        if (!patternEndDate) {
            errors.patternEndDate = t("CONTENT.CUSTOM_RECURRENCE.ValidationRequired");
        }

        if (frequency === 'WEEKLY') {
            if (selectedDaysOfWeek.length === 0) {
                errors.selectedDaysOfWeek = t("CONTENT.CUSTOM_RECURRENCE.ValidationRequired");
            }
        }

        if (frequency === 'MONTHLY') {
            if (monthlyOption === 'dayOfMonth') {
                if (!dayOfMonth || dayOfMonth < 1 || dayOfMonth > 31) {
                    errors.dayOfMonth = t("CONTENT.CUSTOM_RECURRENCE.InvalidDayOfMonth");
                }
            } else if (monthlyOption === 'weekOfMonth') {
                if (!weekOfMonthOrdinal) {
                    errors.weekOfMonthOrdinal = t("CONTENT.CUSTOM_RECURRENCE.ValidationRequired");
                }
                if (!dayOfWeek) {
                    errors.dayOfWeek = t("CONTENT.CUSTOM_RECURRENCE.ValidationRequired");
                }
            }
        }

        if (Object.keys(errors).length > 0) {
            this.setState({ errors, validate: true });
            return;
        }

        const recurrenceData = {
            frequency,
            repeatInterval: parseInt(repeatInterval, 10),
            daysOfWeek: selectedDaysOfWeek,
            patternEndDate,
        };

        if (frequency === 'MONTHLY') {
            if (monthlyOption === 'dayOfMonth') {
                recurrenceData.dayOfMonth = parseInt(dayOfMonth, 10);
                recurrenceData.weekDaysOfMonth = null;
            } else if (monthlyOption === 'weekOfMonth') {
                recurrenceData.dayOfMonth = null;
                recurrenceData.weekDaysOfMonth = [{
                    weekOfMonthOrdinal: parseWeekOfMonthOrdinal(weekOfMonthOrdinal),
                    dayOfWeek: dayOfWeek
                }];
            }
        }

        this.props.onSubmit(recurrenceData);
    }


    render() {
        const { isOpen, onClose, t } = this.props;
        const {
            frequency,
            repeatInterval,
            selectedDaysOfWeek,
            dayOfMonth,
            weekOfMonthOrdinal,
            dayOfWeek,
            patternEndDate,
            monthlyOption,
            errors,
            validate
        } = this.state;

        const frequencyOptions = [
            { key: 'DAILY', value: t('CONTENT.CUSTOM_RECURRENCE.Frequency.Daily') },
            { key: 'WEEKLY', value: t('CONTENT.CUSTOM_RECURRENCE.Frequency.Weekly') },
            { key: 'MONTHLY', value: t('CONTENT.CUSTOM_RECURRENCE.Frequency.Monthly') },
        ];

        const daysOfWeek = [
            { key: 'MONDAY', label: t('DAY.MON') },
            { key: 'TUESDAY', label: t('DAY.TUE') },
            { key: 'WEDNESDAY', label: t('DAY.WED') },
            { key: 'THURSDAY', label: t('DAY.THU') },
            { key: 'FRIDAY', label: t('DAY.FRI') },
            { key: 'SATURDAY', label: t('DAY.SAT') },
            { key: 'SUNDAY', label: t('DAY.SUN') },
        ];

        const weekOfMonthOptions = [
            { key: 'FIRST', value: t('CONTENT.CUSTOM_RECURRENCE.First') },
            { key: 'SECOND', value: t('CONTENT.CUSTOM_RECURRENCE.Second') },
            { key: 'THIRD', value: t('CONTENT.CUSTOM_RECURRENCE.Third') },
            { key: 'FOURTH', value: t('CONTENT.CUSTOM_RECURRENCE.Fourth') },
            { key: 'LAST', value: t('CONTENT.CUSTOM_RECURRENCE.Last') },
        ];

        const daysOfWeekFull = [
            { key: 'MONDAY', value: t('DAY.Monday') },
            { key: 'TUESDAY', value: t('DAY.Tuesday') },
            { key: 'WEDNESDAY', value: t('DAY.Wednesday') },
            { key: 'THURSDAY', value: t('DAY.Thursday') },
            { key: 'FRIDAY', value: t('DAY.Friday') },
            { key: 'SATURDAY', value: t('DAY.Saturday') },
            { key: 'SUNDAY', value: t('DAY.Sunday') },
        ];

        const buttons = [
            { label: t("CONTENT.CUSTOM_RECURRENCE.Submit"), className: styles.saveButton, onClick: this.validateAndSubmit },
            { label: t("CONTENT.CUSTOM_RECURRENCE.Cancel"), className: styles.cancelButton, onClick: onClose }
        ];

        const selectedWeekOption = weekOfMonthOptions.find(opt => opt.key === weekOfMonthOrdinal);
        const selectedDayOfWeekOption = daysOfWeekFull.find(opt => opt.key === dayOfWeek);

        return (
            <Modal
                title={t("CONTENT.CUSTOM_RECURRENCE.Title")}
                isOpen={isOpen}
                onClose={onClose}
                buttons={buttons}
            >
                <div className={styles.container}>
                    <div className={styles.section}>
                        <label className={styles.label}>{t("CONTENT.CUSTOM_RECURRENCE.RepeatEvery")}</label>
                        <div className={styles.repeatEvery}>
                            <CustomInput
                                type="number"
                                value={repeatInterval}
                                onChange={(value) => this.handleInputChange('repeatInterval', value)}
                                customClass={styles.intervalInput}
                                min={1}
                                validate={validate}
                                errorMessage={errors.repeatInterval}
                            />
                            <DropdownSelect
                                options={frequencyOptions}
                                isMultiple={false}
                                selectedOptions={frequencyOptions.filter(opt => opt.key === frequency)}
                                setSelectedOptions={this.handleFrequencyChange}
                                customClass={styles.frequencySelect}
                                placeholder={t("CONTENT.CUSTOM_RECURRENCE.FrequencyPlaceholder")}
                                validate={validate}
                            />
                        </div>
                    </div>

                    {frequency === 'WEEKLY' && (
                        <div className={styles.section}>
                            <label className={styles.label}>{t("CONTENT.CUSTOM_RECURRENCE.SelectDaysOfWeek")}</label>
                            <div className={styles.daysOfWeek}>
                                {daysOfWeek.map(day => (
                                    <button
                                        key={day.key}
                                        type="button"
                                        className={`${styles.dayButton} ${selectedDaysOfWeek.includes(day.key) ? styles.selected : ''}`}
                                        onClick={() => this.toggleDayOfWeek(day.key)}
                                    >
                                        {day.label}
                                    </button>
                                ))}
                            </div>
                            {errors.selectedDaysOfWeek && <div className={styles.error}>{errors.selectedDaysOfWeek}</div>}
                        </div>
                    )}

                    {frequency === 'MONTHLY' && (
                        <div className={styles.section}>
                            <label className={styles.label}>{t("CONTENT.CUSTOM_RECURRENCE.MonthlyOptions")}<span className={styles.requiredMark}> *</span></label>
                            <div className={styles.monthlyOptions}>
                                <div className={styles.option}>
                                    <input
                                        type="radio"
                                        id="dayOfMonth"
                                        name="monthlyOption"
                                        value="dayOfMonth"
                                        checked={monthlyOption === 'dayOfMonth'}
                                        onChange={() => this.handleMonthlyOptionChange('dayOfMonth')}
                                    />
                                    <label htmlFor="dayOfMonth" className={styles.label}>{t("CONTENT.CUSTOM_RECURRENCE.OnDay")}</label>
                                    <CustomInput
                                        type="number"
                                        value={dayOfMonth}
                                        onChange={(value) => this.handleInputChange('dayOfMonth', value)}
                                        customClass={styles.dayOfMonthInput}
                                        min={1}
                                        max={31}
                                        disabled={monthlyOption !== 'dayOfMonth'}
                                        validate={validate && monthlyOption === 'dayOfMonth'}
                                        errorMessage={errors.dayOfMonth}
                                    />
                                </div>
                                <div className={styles.option}>
                                    <input
                                        type="radio"
                                        id="weekOfMonth"
                                        name="monthlyOption"
                                        value="weekOfMonth"
                                        checked={monthlyOption === 'weekOfMonth'}
                                        onChange={() => this.handleMonthlyOptionChange('weekOfMonth')}
                                    />
                                    <label htmlFor="weekOfMonth" className={styles.label}>{t("CONTENT.CUSTOM_RECURRENCE.OnThe")}<span className={styles.requiredMark}> *</span></label>
                                    <DropdownSelect
                                        options={weekOfMonthOptions}
                                        isMultiple={false}
                                        selectedOptions={selectedWeekOption ? [selectedWeekOption] : []}
                                        setSelectedOptions={(selected) => this.handleInputChange('weekOfMonthOrdinal', selected[0]?.key)}
                                        customClass={styles.weekOfMonthSelect}
                                        disabled={monthlyOption !== 'weekOfMonth'}
                                        placeholder={t("CONTENT.CUSTOM_RECURRENCE.WeekOfMonthPlaceholder")}
                                        validate={validate && monthlyOption === 'weekOfMonth'}
                                        errorMessage={errors.weekOfMonthOrdinal}
                                    />
                                    <br />
                                    <DropdownSelect
                                        options={daysOfWeekFull}
                                        isMultiple={false}
                                        selectedOptions={selectedDayOfWeekOption ? [selectedDayOfWeekOption] : []}
                                        setSelectedOptions={(selected) => this.handleInputChange('dayOfWeek', selected[0]?.key)}
                                        customClass={styles.dayOfWeekSelect}
                                        disabled={monthlyOption !== 'weekOfMonth'}
                                        placeholder={t("CONTENT.CUSTOM_RECURRENCE.DayOfWeekPlaceholder")}
                                        validate={validate && monthlyOption === 'weekOfMonth'}
                                        errorMessage={errors.dayOfWeek}
                                    />

                                </div>
                                {errors.monthlyOption && <div className={styles.error}>{errors.monthlyOption}</div>}
                            </div>
                        </div>
                    )}

                    <div className={styles.section}>
                        <label className={styles.label}>{t("CONTENT.CUSTOM_RECURRENCE.PatternEndDate")}<span className={styles.requiredMark}> *</span></label>
                        <DateTimeInput
                            value={patternEndDate}
                            label={t("CONTENT.CUSTOM_RECURRENCE.PatternEndDate")}
                            onChange={this.handleDateChange}
                            customClass={styles.dateInput}
                            type="date"
                            validate={validate}
                            errorMessage={errors.patternEndDate}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

export default withTranslation()(ContentCustomRecurrence);
